import React from "react";
import {
  inputChangeHandler
} from "../../CommonFunctions";
import clarieniLogo from "../../images/clarieniLogo.png";
import Icons from "../../components/Icons/Icon";
import Typography from "../../components/Typography/Typography";
import { Formik, Form} from "formik";
import Spinner from "../../components/Spinner/Spinner";
import SupportModal from "./SupportModal";

interface ErrorLogClientProps {
  submitHandler:any;
  setPasswordShow:any;
  isFromSubmiting:boolean;
  isHide:boolean;
  setPasswordData:any;
  password:string;
  errorMessage:string | null;
}

const ErrorLogClient = ({ 
  submitHandler,
  setPasswordShow,
  isFromSubmiting,
  isHide,
  errorMessage,
  setPasswordData,
  password
}: ErrorLogClientProps) => {
  return (
    <div className="min-h-screen w-full bg-gray-400">
      <div className="pt-[4.5rem] flex justify-center">
        <img src={clarieniLogo} alt={clarieniLogo} className="max-w-full" />
      </div>
      <div className="flex justify-center mt-[4.625rem]">
        <Icons name="errorBg" variant="bold" size={65} className="errorclient" />
      </div>
      <div className="grid grid-cols-5 justify-center">
        <div></div>
        <div className="col-span-3 mx-20">
          <Formik
            initialValues={{
                password:password
            }}
            onSubmit={submitHandler}
          >
            {({ setValues, values }) => (
              <Form>
                <div className="">
                  <div className="flex justify-center text-center mt-2">
                    <Typography variant="h3" className="text-gray-300 font-bold">
                      Please enter your password to view this portfolio
                    </Typography>
                  </div>
                  <div className="flex justify-center text-center mt-2">
                    <Typography
                      size="xxl"
                      variant="p"
                      className="text-gray-500 font-normal"
                    >
                      This is a protected portfolio intended for review only by
                      authorized users. Please enter the password assigned by your
                      Millipixels representative to view the portfolio.
                    </Typography>
                  </div>
                  <div className="flex justify-center mt-6 pb-10 border-b border-gray-800">
                    <div className="w-[17rem] p-[0.063rem] relative border border-primary-100 bg-white z-10 rounded-md overflow-hidden group">
                      <input
                        name="password"
                        onChange={(event) => {
                          inputChangeHandler(event.target.value, {
                            setValues: setValues,
                            key: "password",
                            formData: values,
                          });
                          setPasswordData(event.target.value);
                        }}
                        type={isHide?"password":"text"}
                        value={values["password"]}
                        className="text-gray-300 text-500 font-normal ps-4 pe-10 py-[11px] bg-white border-none outline-none z-20 rounded-[0.313rem] w-full placeholder:text-gray-700 peer"
                        placeholder="Password"
                      />
                      <span
                        className="absolute top-0 left-0 w-[0%] h-[0%] -z-10 bg-primary-100 peer-focus:w-full f peer-focus:h-full"
                        style={{ transition: "height 0.5s, width 0.5s 0.3s" }}
                      ></span>
                      <div className="flex justify-start items-center w-full px-6 py-1.5 bg-primary-100 rounded-b-md">
                        <Icons name="errorWhite" variant="stroke" size={15} />
                        <span className="text-200 font-normal text-white ms-1.5">
                          {errorMessage}
                        </span>
                      </div>
                      <span onClick={setPasswordShow}>
                        {isHide?(
                          <Icons
                            name="show888"
                            variant="preview"
                            size={20}
                            className="absolute top-4 right-4 cursor-pointer"
                          />
                        ):(
                          <Icons
                            name="hide888"
                            variant="preview"
                            size={20}
                            className="absolute top-4 right-4"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[3.75rem]">
                    <button 
                      className="bg-secondary-100 py-[1.125rem] px-[1.875rem] rounded-md 
                        text-500 font-medium text-white transition-all hover:scale-105"
                      disabled={isFromSubmiting}
                    >
                      {isFromSubmiting ? (
                        <span className="flex"><Spinner labal="Processing..." /></span>
                      ) : (
                        <span>View Portfolio</span>
                      )}
                    </button>
                  </div>
                  <div className="flex justify-center mt-7 pb-14">
                    <a
                      href="#"
                      className="text-400 fonr-normal text-secondary-100 border-b border-secondary-100"
                      onClick={()=>{
                        SupportModal.fire();
                      }}
                    >
                      Need Support?
                    </a>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ErrorLogClient;