import React from "react";
import millipixelsLogo from "../../images/millipixelsLogo.png";
import Icons from "../../components/Icons/Icon";
import Typography from "../../components/Typography/Typography";
import clientImage from "../../images/client.png";
import Checkbox from "../../components/CheckBox/CheckBox";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const WelcomeClient = () => {
  const navigte = useNavigate();
  return (
    <div className="min-h-screen w-full bg-gray-400">
      <div className="pt-[4.5rem] flex justify-center max-md:pt-[3.625rem]">
        <img src={millipixelsLogo} alt={millipixelsLogo} className="max-w-full max-md:w-[13.75rem] max-md:h-[3.125rem]" />
      </div>
      <div className="flex justify-center mt-[2.625rem]">
        <Icons name="waveBg" variant="bold" size={65} className="wave" />
      </div>
      <div className="grid grid-cols-5 justify-center">
        <div className="xl:block lg:hidden"></div>
        <div className="xl:col-span-3 col-span-5 md:mx-20 max-md:mx-5">
          <div className="flex justify-center text-center mt-2">
            <Typography variant="h3" className="text-gray-300 font-bold">
              Hey there!
            </Typography>
          </div>
          <div className="flex justify-center text-center mt-3">
            <Typography
              size="xxl"
              variant="p"
              className="text-gray-500 font-normal"
            >
              Thanks for taking the time to view this portfolio. We have tried
              to include some of the more recent works in this presentation.
              However, feel free to get in touch with us for any clarifications
              or additional information you may need.
            </Typography>
          </div>
          <div className="w-full mt-6 pb-10 border-b border-gray-800">
            {/*<div className="grid grid-cols-2 gap-5 max-md:grid-cols-1">
              <div className="p-[0.438rem] bg-white rounded-full flex justify-start items-center suply">
                <div className="w-[4.25rem] h-[4.438rem] rounded-full overflow-hidden relative">
                  <img
                    src={clientImage}
                    alt={clientImage}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ms-4 profile-content me-4">
                  <Typography variant="h5" className="text-black font-bold name break-words">
                    Damien P. Hernandez
                  </Typography>
                  <Typography
                    size="lg"
                    variant="p"
                    className="text-black font-normal desig"
                  >
                    Web Solutions Consultant
                  </Typography>
                  <Typography
                    size="sm"
                    variant="p"
                    className="text-black font-normal email"
                  >
                    damien.ph@Millipixels.com
                  </Typography>
                </div>
              </div>
              <div className="w-full flex justify-start items-center bg-white rounded-full p-[1.313rem] calling">
                <Icons name="callBg" variant="bold" size={42} />
                <div className="ms-4 calling-content">
                  <Typography
                    size="lg"
                    variant="p"
                    className="text-black font-normal opacity-60 call-text"
                  >
                    Call Us
                  </Typography>
                  <Typography variant="h4" className="text-black font-bold call-number">
                    +1 (342) 243-2343
                  </Typography>
                </div>
              </div>
            </div>*/}
              <div className="flex justify-center">
                <button 
                  className="bg-secondary-100 py-[1.125rem] px-[1.875rem] rounded-md text-500
                  font-medium text-white transition-all hover:scale-105"
                  onClick={()=>{navigte('/client/portfolio')}}
                >
                  View Portfolio
                </button>
              </div>
          </div>
          <div className="mt-10 pb-14 max-md:mt-10">
            <div className="flex justify-center items-center mb-6">
              <Link to="/client/contactus" className="ms-2 text-400 font-normal text-gray-300">Need Support?</Link>
            </div>
            {/* <div className="flex justify-center items-center mb-6">
              <Checkbox checked onChange={function noRefCheck() {}} size="sm" />
              <label className="ms-2 text-400 font-normal text-gray-300">Yes, I have read the <a href="#" className="text-seconday-100 border-b border-seconday-100">data privacy statement</a> and accept it.</label>
            </div>
            <div className="flex justify-center">
              <button 
                className="bg-secondary-100 py-[1.125rem] px-[1.875rem] rounded-md text-500
                 font-medium text-white transition-all hover:scale-105"
                onClick={()=>{navigte('/client/portfolio')}}
              >
                View Portfolio
              </button>
            </div> */}
          </div>
        </div>
        <div className="xl:block lg:hidden"></div>
      </div>
    </div>
  );
};
